<template>
  <DialogFormWrapper>
    <template #form>
      <div class="mt-6 mb-8 black--text">
        {{ description }}
      </div>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        v-shortkey="{enter:['enter']}"
        @shortkey.native="confirm()"
        @click="confirm()"
      >
        Potwierdź
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog,
      isProcessing (state) {
        const { tableName } = this.dialog.data
        return state[tableName]?.isProcessing
      }
    }),
    description () {
      return this.dialog.data.description || 'Czy jesteś pewien?'
    }
  },
  mounted () {
    return new Promise((resolve) => {
      this.resolve = resolve
    }).then(() => {
      this.dialog?.data?.callback()
    })
  },
  methods: {
    confirm () {
      this.resolve()
    },
  }
}
</script>
